@import '~antd/dist/antd.less';

body {
  font-weight: bold;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.ant-btn {
  font-weight: bold;
}

.anticon.anticon-search {
  color: #ca0530;
}

.disabled-form-item {
  label {
    color: #dcdce2 !important;
  }
}

.input-transparent {
  &.ant-input,
  &.ant-input-password {
    background-color: transparent;
    color: #78787d;
    font-weight: bold;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    &:hover {
      background-color: transparent;
    }

    &:focus {
      box-shadow: none;
    }
  }
  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  &.ant-input-disabled {
    color: #dcdce2;
    border-bottom: 0;
  }
}

.login-container {
  position: absolute;
  left: calc(50% - 300px);
  max-width: 100vw;

  @media (max-width: 992px) {
    left: 0;
    width: 100%;
  }
}

.form-container {
  text-align: left;
  position: relative;
  left: -300px;

  @media (max-width: 992px) {
    left: 0;
  }

  .background-container {
    position: absolute;
    width: 100%;
    margin: auto;

    @media (max-width: 992px) {
      overflow: hidden;
    }
  }
}

.login-form {
  margin-left: 150px;

  @media (max-width: 992px) {
    margin-left: 5px;

    h1 {
      font-size: 60px !important;
    }
  }

  .ant-input,
  .ant-input-password {
    background-color: transparent;
    color: white;
    font-weight: bold;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      box-shadow: none;
    }
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  .anticon {
    color: white;
  }

  .ant-form-item-label > label.ant-form-item-required {
    color: white !important;
    font-weight: bold;
    &::before,
    &::after {
      display: none !important;
    }
    margin-right: 30px;
  }

  .ant-checkbox-wrapper {
    font-weight: bold;
  }

  h1,
  h2,
  .ant-checkbox-wrapper {
    color: white !important;
  }

  h1 {
    font-size: 90px;
    margin-bottom: 0;
    margin-top: 50px;
  }

  h2 {
    font-size: 15px;
    margin: 0 !important;
    letter-spacing: 5px;
  }
}

.logo-button {
  text-align: center;
  display: inline-block;
  img {
    max-height: 40px;
  }
  div {
    color: #78787d;
    text-transform: uppercase;
    font-size: 10px;
  }
}

.ant-layout-header {
  @media (max-width: 992px) {
    padding: 5px 10px !important;
    height: auto !important;
  }

  .user-col {
    line-height: 15px;
    text-align: right;
    padding-right: 38px !important;

    .ant-btn {
      box-shadow: none;
    }

    .anticon {
      font-size: 30px;
    }

    @media (max-width: 992px) {
      .anticon {
        font-size: 22px !important;
      }
    }
  }
}

.ant-layout-header .logo-button {
  position: relative;
  top: -15px;
}

.header-social-container {
  text-align: right;
  position: relative;
  line-height: 37px;

  @media (max-width: 992px) {
    padding-right: 10px !important;
  }

  > * {
    margin: 0 3px;
  }

  .ant-btn.facebook {
    color: white;
    background-color: #3b5a9a;
    border-color: #3b5a9a;
  }

  .ant-btn.youtube {
    color: white;
    background-color: #c3191e;
    border-color: #c3191e;
  }

  .ant-btn.linkedin {
    color: white;
    background-color: #0073b2;
    border-color: #0073b2;
  }

  .ant-btn.instagram {
    color: white;
    background-color: #833ab4;
    border-color: #833ab4;
  }
}

.home {
  .ant-carousel {
    .slick-dots {
      li,
      li.slick-active {
        width: 12px;
        button {
          height: 12px;
          border-radius: 12px;
          background: black;
        }
      }
    }
  }

  .background-filter {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .half {
    height: 200px;
    overflow: hidden;
    position: relative;

    .home-card {
      height: 200px;

      /* .background {
        position: absolute;
        top: -100px;
      } */

      .background img {
        height: auto;
        width: 100%;
      }

      .foreground {
        z-index: 2;
        height: 100%;
        top: 0;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1.ant-typography {
          margin-bottom: 0;
          color: white;
          font-size: 40px;
        }
      }

      button {
        top: 144px !important;
      }
    }
  }

  .home-card {
    height: 400px;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        max-width: 100%;
        height: 100%;
      }
    }

    .foreground {
      z-index: 2;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1.ant-typography {
        text-transform: uppercase;
        font-size: 50px;
        color: white;
      }

      p {
        margin-bottom: 7px;
      }
    }

    button {
      position: relative;
      top: 360px;
      text-transform: uppercase;
      z-index: 2;
    }
  }

  .big-button {
    p {
      line-height: 25px;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.pitch {
  .background {
    font-size: 90px;
    color: #dcdce2;
    text-transform: uppercase;
    font-weight: bolder;

    @media (max-width: 992px) {
      font-size: 50px !important;
    }
  }

  .foreground {
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    top: -90px;
    font-size: 23px;

    @media (max-width: 992px) {
      font-size: 18px !important;
      top: -56px;
    }

    &.large {
      top: -109px;
      font-size: 50px;

      @media (max-width: 992px) {
        font-size: 30px !important;
      }
    }
  }
}

.store-card {
  position: relative;
  max-width: 310px;
  .background {
    height: 320px;
    overflow: hidden;

    img {
      max-height: 100%;
    }
  }

  .foreground {
    position: absolute;
    top: 0;
    width: calc(100% - 25px);
    margin-top: 35px;
    img {
      max-width: 70%;
    }
    div {
      color: white;
      font-size: 30px;
      text-transform: uppercase;
    }
  }

  button {
    position: relative;
    top: -20px;
    text-transform: uppercase;
  }
}

.store-card:not(:hover) {
  .background {
    filter: grayscale() brightness(0.7);
  }
}

.contact-card {
  .ant-card-head {
    padding: 0;
  }

  .ant-card-head-title {
    padding: 0;
    height: 100px;
  }

  p {
    margin-bottom: 10px;
  }
}

.contact-title {
  max-height: 100px;
  .background {
    overflow: hidden;
    img {
      max-width: 100%;
      position: relative;
      top: -150px;
      padding: 0;
    }
  }

  .foreground {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;

    img {
      max-height: 90px;
      margin-top: 5px;
    }

    span {
      color: white;
      font-size: 40px;
      text-transform: uppercase;
      position: relative;
      top: 15px;
      right: -5px;
    }
  }
}

.header-menu {
  .ant-btn {
    border: none;
    box-shadow: none;
    text-transform: uppercase;
    font-weight: bold;

    &:not(.ant-btn-primary) {
      color: #78787d;
    }
  }
}

.header-menu-list {
  .ant-btn {
    color: #78787d;
    text-transform: uppercase;
    transition: none;
    &:hover {
      background: none;
    }
  }

  .ant-dropdown-menu-item-active {
    background-color: #CD1619;
    .ant-btn {
      color: white;
    }
  }
}

.settings-form,
.request-form {
  .ant-form-item-label > label {
    font-weight: bold;
    color: #78787d;
  }

  .ant-form-item-label > label:not(.ant-form-item-required) {
    margin-right: 10px;
    &::after {
      display: none !important;
    }
  }

  .ant-form-item-label > label.ant-form-item-required {
    &::before {
      display: none !important;
    }

    &::after {
      display: inline-block;
      margin-right: 4px;
      color: #CD1619;
      font-size: 16px;
      position: relative;
      content: '*';
      top: -2px;
      left: -1px;
    }
  }
}

.technical-documents {
  .document-type-card {
    max-width: 100vw;
    height: 290px;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      max-height: 290px;
      max-width: 100vw;
      overflow: hidden;
      img {
        max-width: 100%;
      }
    }

    .foreground {
      position: absolute;
      left: 0;
      width: 100%;
      max-width: 100vw;
      text-align: center;
      margin-top: 80px;
      font-size: 18px;

      h1.ant-typography {
        text-transform: uppercase;
        font-size: 33px;
      }
    }
  }
}

.ant-btn.primary-inverted {
  color: #CD1619;
  border-color: #CD1619;
}

.orders-collapse {
  background-color: transparent;
  border: none;

  .ant-collapse-header,
  .ant-collapse-content-box {
    padding: 0;
  }
}

.orders-table {
  margin-top: 20px;
  .ant-table-thead {
    > tr > th {
      background-color: transparent;
      color: #78787d;
    }

    .ant-table-filter-trigger-container {
      .anticon.anticon-search {
        color: #78787d;
      }
    }
  }

  .ant-table-cell {
    color: #78787d;
  }
}

.filter-form {
  background-color: #eeedf3;
  padding: 6px 10px;
  justify-content: center;
  label {
    color: #78787d;
  }

  .ant-input-affix-wrapper,
  .ant-input,
  .ant-picker-input,
  .ant-picker,
  .ant-select,
  .ant-select-selector {
    background-color: transparent !important;
    font-weight: bold;
    color: #78787d;

    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    &:hover {
      background-color: transparent;
    }

    &:focus,
    &.ant-input-affix-wrapper-focused,
    &.ant-picker-focused {
      box-shadow: none;
    }
  }
}

.document-card {
  text-align: left;
  padding: 10px 7.5px;
  .ant-btn-link {
    padding: 0;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .document-size {
    color: #78787d;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(221, 50, 20, 0.5);

    display: flex;
    flex-direction: column;
    justify-content: center;

    & > * {
      margin: auto;
    }

    .ant-btn {
      background-color: transparent;
      color: white;
      border: 3px solid white;
      height: 70px;
      width: 70px;

      .anticon {
        font-size: 50px;
        line-height: 0px;
      }
    }
  }

  .document-container {
    &:not(:hover) {
      .overlay {
        display: none;
      }
    }

    position: relative;
    display: inline-block;
    line-height: 0;
    img,
    video {
      max-width: 100%;
    }
  }
}

.visible-sm {
  display: none;
}

@media (max-width: 992px) {
  .visible-xl {
    display: none;
  }
  .visible-sm {
    display: initial !important;
  }
}

@primary-color: #CD1619;